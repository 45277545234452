





















import { apiCategoryLists } from '@/api/goods'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {},
})
export default class Shop extends Vue {
    $refs!: { cascader: any }
    @Prop() value!: any

    lists = []
    get params() {
        return this.value
    }
    getLists() {
        apiCategoryLists({ pager_type: 1 }).then((res) => {
            this.lists = res?.lists
        })
    }
    onSelect() {
        const node = this.$refs.cascader.getCheckedNodes()
        this.$emit('input', node[0].data)
    }
    created() {
        this.getLists()
    }
}
