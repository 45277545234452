
















import { apiLinkList } from '@/api/app'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {},
})
export default class Shop extends Vue {
    @Prop() value!: any

    linkList = []
    
    get link() {
        return this.value
    }
    set link(val) {
        this.$emit('input', val)
    }

    getLists() {
        apiLinkList().then((res) => {
            this.linkList = res
        })
    }
    created() {
        this.getLists()
    }
    
}
