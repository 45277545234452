


















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class StyleChose extends Vue {
    /** S props **/

    @Prop({ default: () => [] }) data!: any[]
    @Prop() value!: number | string
    /** E props **/

    /** S computed **/

    get select() {
        return this.value
    }

    set select(val) {
        this.$emit('input', val)
    }

    /** E computed **/
}
