















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Goods from '@/components/goods-select/detail.vue'
import Shop from './shop.vue'
import Category from './category.vue'
import Page from './page.vue'
import Custom from './custom.vue'
@Component({
    components: {
        Goods,
        Shop,
        Category,
        Page,
        Custom,
    },
})
export default class Detail extends Vue {
    @Prop() value!: any
    linkType = 'shop'
    lists: any [] = [
        {
            name: '商城页面',
            type: 'shop',
            link: {
                path: '',
                name: '',
                params: {},
                type: 'shop',
            },
        },
        {
            name: '单一商品',
            type: 'goods',
            link: {
                path: '/pages/goods_detail/goods_detail',
                name: '商品详情',
                params: {},
                type: 'goods',
            },
        },
        {
            name: '商品分类',
            type: 'category',
            link: {
                path: '/pages/goods_search/goods_search',
                name: '商品分类',
                params: {},
                type: 'category',
            },
        },
        {
            name: '微页面',
            type: 'page',
            link: {
                path: '/pages/page/page',
                name: '微页面',
                params: {},
                type: 'page',
            },
        },
        {
            name: '自定义链接',
            type: 'custom',
            link: {
                path: '/pages/webview/webview',
                name: '自定义链接',
                params: {
                   url: ''
                },
                type: 'custom',
            },
        },
    ]

    get link(): any {
        const item = this.lists.find((item) => item.type == this.linkType)
        return item?.link
    }
    set link(val) {
        this.lists.forEach((item) => {
            if (item.type == this.linkType) {
                val && (item.link = val)
            }
        })
    }

    @Watch('value', { immediate: true })
    valueChange(val: any) {
        if (!val) return 
        this.lists.forEach((item) => {
            if (item.type == val.type) {
                item.link = val
            }
        })
        this.linkType = val.type || 'shop'
    }

    @Watch('link', { deep: true })
    linkChange(val: any) {
        this.$emit('input', val)
    }

    setLinkParams(item: any) {
        if (!item.id) return
        this.link &&
            (this.link = {
                ...this.link,
                params: { id: item.id, name: item.name },
            })
    }
}
